import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Sections from "./../components/sections/Sections";
import Popup from "../components/Popup/Popup";

const SecondPage = ( props ) => (
  <Layout route="butelkowanie-i-logistyka">
    {/* <Popup /> */}
    <SEO title="Butelkowanie i logistyka" />
    <Sections
        data={ props.data.wordpressPage.acf.sections_page }
        pageId={ props.data.wordpressPage.id }
      />
      <section className="boxes">
            <div className="wrapper-boxes">
                {/* <div className="col">
                    <h2>Butelkowanie i Logistyka</h2>
                    <div className="text-box" dangerouslySetInnerHTML={ { __html: `
                    Jesteśmy jedną z najbardziej doświadczonych rozlewni kontraktowych win w Polsce. W naszym zakładzie oferujemy pełną obsługę niestandardowego butelkowania oraz indywidualnymi wycenami przygotowanymi specjalnie do Twoich potrzeb.</br></br>

                    Pełna obsługa butelkowania wina obejmuje:</br>
                    • Butelka</br>
                    • Przepłukiwanie azotem</br>
                    • Próżniowe Napełnianie Grawitacyjne</br>
                    • Korkowanie próżniowe</br>
                    • Automatyczna aplikacja kapsułek</br>
                    • Przędzenie folii (laminaty cynowe, aluminiowe i polilaminatowe)</br>
                    • Etykiety samoprzylepne</br>
                    • Automatyczne zamykanie skrzynek</br>
                    • Wlewamy wino do zakrętki i korka</br></br>

                    Usługa częściowego pakietu obejmuje:</br>
                    • Sparowanie butelek, napełnianie, korkowanie próżniowe</br>
                    LUB</br>
                    • Etykietowanie` } } /> 
                </div> */}
                <div className="col">
                    <h2>Bootling and logistics</h2>
                    <div className="text-box" dangerouslySetInnerHTML={ { __html: `
                    We are one of the most experienced bottlers of contract wines in Poland. We offer full service at our facility custom bottling and individual quotes prepared especially for your needs.</br></br>
                    
                    
                    A complete wine bottling service includes:</br>
                    • Bottle</br>
                    • Sparing with Nitrogen</br>
                    • Vacuum Gravity Filling</br>
                    • Vacuum corking</br>
                    • Automatic Capsule application</br>
                    • Foil spinning (Tin, Aluminum and Polyl Laminates)</br>
                    • Adhesive labels</br>
                    • Automatic Case sealing</br>
                    • We pour wine into screw cap and cork</br></br>
                    
                    Partial Package Service includes either:</br>
                    • Bottle Sparging, flling, vacuum corking</br>
                    OR</br>
                    • Labeling
                    <img class="bg" src="https://backend-prinz.norddigital.com/wp-content/uploads/2023/05/img62n831t893.png" alt="background" />` } } /> 
                    {/* <Img className="bg" fluid={image.img.childImageSharp.fluid} /> */}
                </div>
            </div>
        </section>
        <section className="btn-section">
            <a href="https://backend-prinz.norddigital.com/wp-content/uploads/2023/05/butelkowaniecompressed.pdf" download>ZOBACZ KATALOG</a>
        </section>
  </Layout>
)
export const pageQuery = graphql`
  query {
    wordpressPage(wordpress_id: { eq: 1241 }) {
      id
      slug
      title
      wordpress_id
      content
      acf {
        sections_page {
          __typename
          ... on WordPressAcf_slider_text_section {
            id
          }
          ... on WordPressAcf_contact_section {
            id
          }
          ... on WordPressAcf_image_header_editor_section {
            id
          }
          ... on WordPressAcf_bookmark_section {
            id
          }
          ... on WordPressAcf_products_section {
            id
          }
          ... on WordPressAcf_map_section {
            id
          }
        }
      }
    }
    site {
      id
      siteMetadata {
        title
      }
    }
    wordpressSiteMetadata {
      home
    }
  }
`;

export default SecondPage
